import React from "react"
import { css } from "@emotion/core"

import Layout from "../components/Layout"
import SEO from "../components/Seo"
import Wrapper from '../components/Wrapper'

const NotFoundPage = () => (
  <Layout>
    <SEO title="404: Not found" />
    <Wrapper margin="2em">
      <h1>NOT FOUND</h1>
      <p>You just hit a route that doesn't exist... the sadness.</p>
    </Wrapper>
  </Layout>
)

export default NotFoundPage
